import { inject, Injectable } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import Cookies from "js-cookie";
import moment from "moment";
import { filter } from "rxjs";
import { RoutingService } from "src/app/shared/services/routing/routing.service";

@Injectable({ providedIn: "root" })
export class RoutingSetupService {
   private hasRun: boolean = false;

   private readonly router = inject(Router);
   private readonly routingService = inject(RoutingService);

   public runSetup(): void {
      if (this.hasRun) return;
      this.setPreNavChecks();
      this.trackLastPage();
      this.hasRun = true;
   }

   /**
    * Sets the lastPage cookie on route change.
    * This cookie is used by the auth-server after a successful login to direct the
    * user to where they were before being logged out.
    */
   private trackLastPage() {
      this.router.events
         .pipe(
            filter(
               (event): event is NavigationStart | NavigationEnd =>
                  //We catch both events here, in case a user is logged out halfway through a navigation.
                  //We want the auth server to know where they were _attempting_ to go, even if they didn't make it.
                  //We continue to watch for NavigationEnd because it could contain a more accurate URL.
                  event instanceof NavigationEnd || event instanceof NavigationStart,
            ),
         )
         .subscribe((event) => {
            const currentRouteNames = this.routingService.getAllRouteNames();
            const blacklistRoutes = [
               "viewExternalTask",
               "dashboard",
               "mobileDashboard",
               "mobile-loc-problem",
               "loc-problem",
               "check-work-requests",
               "problem",
               "global-problem",
               "thanks",
               "inactiveWarning",
            ];
            const currentRouteIsBlacklisted = currentRouteNames.some((routeName) =>
               blacklistRoutes.includes(routeName as any),
            );
            if (currentRouteIsBlacklisted) {
               Cookies.remove("lastPage", {
                  path: "/",
                  domain: this.routingService.getDomain(),
               });
               return;
            }
            const cookieOptions = {
               expires: moment().add(30, "days").toDate(),
               domain: this.routingService.getDomain(),
               path: "/",
            };
            if (event instanceof NavigationEnd) {
               Cookies.set("lastPage", event.urlAfterRedirects, { ...cookieOptions });
               return;
            }
            Cookies.set("lastPage", event.url, { ...cookieOptions });
         });
   }

   /** Sets up some logic that needs to run before every route change */
   private setPreNavChecks() {
      this.router.events
         .pipe(
            filter((event): event is NavigationStart => event instanceof NavigationStart),
         )
         .subscribe((event) => {
            if (event.url.startsWith("/#!/")) {
               //We are attempting to navigate to a legacy route that was used in AngularJS.
               //Redirect to the same route but without the `#!` prefix.
               this.router.navigateByUrl(event.url.slice(3));
               return;
            }
            if (event.url.includes("?m=true")) {
               //We may be attempting to navigate to a legacy mobile route that was used in
               //AngularJS. If so, redirect to the same route but without the `#!` prefix, and move
               //the query to the end.
               if (event.url.startsWith("?m=true/#!/")) {
                  this.router.navigateByUrl(`${event.url.slice(10)}?m=true`);
                  return;
               }
               if (event.url.startsWith("?m=true#!/")) {
                  this.router.navigateByUrl(`${event.url.slice(9)}?m=true`);
                  return;
               }
               if (event.url.startsWith("/?m=true/#!/")) {
                  this.router.navigateByUrl(`${event.url.slice(11)}?m=true`);
                  return;
               }
               if (event.url.startsWith("/?m=true#!/")) {
                  this.router.navigateByUrl(`${event.url.slice(10)}?m=true`);
                  return;
               }
            }
            if (event.url.startsWith("/#%21/")) {
               // This was put in place to account for sharepoint being crappy.
               // For some reason it insists on url encoding the "!" Bang in the legacy urls,
               // so this is meant to catch that and correct it.
               this.router.navigateByUrl(event.url.slice(5));
            }
         });
   }
}

import { HttpClient } from "@angular/common/http";
import { inject } from "@angular/core";
import type {
   TranslocoLoader,
   TranslocoLoaderData,
} from "@jsverse/transloco/lib/transloco.loader";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";

export class TranslationsHttpLoader implements TranslocoLoader {
   private readonly http = inject(HttpClient);

   /**
    * Loads the translations bases on the language and scope
    *
    * @param lang
    * @param data
    */
   public getTranslation(lang: string, data?: TranslocoLoaderData) {
      const scope = data?.scope;

      // TODO: Remove this once we move translations to S3
      const baseUrl = `/assets/i18n/new`;
      // const baseUrl = `https://limble-dev-translations.s3.us-west-1.amazonaws.com`
      let globalUrl = `${baseUrl}/${lang}.json`;

      if (!scope) {
         return this.http.get(globalUrl);
      }

      // Removing the scope from the url since it is included in the lang parameter
      globalUrl = globalUrl.replace(`${scope}/`, "");
      const scopeUrl = `${baseUrl}/${lang}.json`;

      return forkJoin([this.http.get(globalUrl), this.http.get(scopeUrl)]).pipe(
         map(([globalTranslations, scopeTranslations]) => ({
            ...globalTranslations,
            ...scopeTranslations,
         })),
      );
   }
}

@if (playAnimation === true) {
   <div class="loading-animation">
      <lim-ui-logo-animation animationSize="large" />
   </div>
}
@if (appResume === true) {
   <div class="invisible-loading-bar"></div>
}
<div [hidden]="playAnimation === true" class="route-container">
   <router-outlet />
</div>

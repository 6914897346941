import axios, { type AxiosError, type AxiosRequestConfig } from "axios/dist/axios";
import { RETRY_COUNT } from "src/app/shared/interceptors/retry-interceptor";

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
   retryCount?: number;
   retryDelay?: number;
   __retryCount?: number;
}

export function initializeRetryAxiosInterceptor(): void {
   axios.interceptors.response.use(undefined, async (error: AxiosError) => {
      if (axios.isAxiosError(error) && error.config && error.response?.status === 503) {
         const config = error.config as CustomAxiosRequestConfig;

         config.retryCount ??= RETRY_COUNT;

         config.__retryCount ??= 1;

         if (config.retryCount && config.__retryCount <= config.retryCount) {
            config.__retryCount = config.__retryCount + 1;
            const backoffDelay = config.retryDelay ?? 1000;

            await new Promise((resolve) => {
               setTimeout(resolve, backoffDelay * Math.pow(2, config.__retryCount ?? 0));
            });
            return axios(config);
         }
      }

      return Promise.reject(error);
   });
}

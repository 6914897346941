import { inject, Injectable } from "@angular/core";
import type { AxiosError } from "axios/dist/axios";
import axios from "axios/dist/axios";
import { ConnectionService } from "src/app/lite/connection/connection.service";
import { InternetDisconnectedError } from "src/app/lite/connection/internet-disconnected-interceptor/internet-disconnected.error";

@Injectable({ providedIn: "root" })
export class InternetDisconnectedAxiosInterceptorService {
   private readonly connection = inject(ConnectionService);

   public setInterceptor(): void {
      axios.interceptors.response.use(undefined, async (error) =>
         this.checkForDisconnection(error),
      );
   }

   private async checkForDisconnection(error: unknown): Promise<never> {
      const disconnected = await this.communicationWithLimbleServersIsLost(error);
      if (!disconnected) throw error;
      this.connection.setNoInternet();
      throw new InternetDisconnectedError();
   }

   /**
    * @returns true if the request was sent successfully but no response was received
    * from the server -- not even an error response.
    *
    * Note that this will return true in the case of CORS errors as well as actual
    * connectivity issues.
    * https://github.com/axios/axios/issues/838
    */
   private isNoResponseError(error: AxiosError<unknown>): boolean {
      // https://github.com/axios/axios?tab=readme-ov-file#handling-errors
      return error.request !== undefined && error.response === undefined;
   }

   private async communicationWithLimbleServersIsLost(error: unknown): Promise<boolean> {
      return (
         this.connection.isOnline() &&
         axios.isAxiosError(error) &&
         this.isNoResponseError(error) &&
         (await this.connection.isPingSuccess()) === false
      );
   }
}

import {
   type HttpEvent,
   type HttpHandler,
   type HttpInterceptor,
   type HttpRequest,
   HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { type Observable, throwError, timer } from "rxjs";
import { catchError, retry } from "rxjs/operators";

export const RETRY_COUNT = 3;

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
   public intercept(
      req: HttpRequest<any>,
      next: HttpHandler,
   ): Observable<HttpEvent<any>> {
      return next.handle(req).pipe(
         retry({
            count: RETRY_COUNT,
            delay: (errorRes, retryIndex) => {
               if (errorRes instanceof HttpErrorResponse && errorRes.status === 503) {
                  const backoffDelay = retryIndex ?? 1000;
                  return timer(backoffDelay);
               }
               return throwError(() => errorRes);
            },
         }),
         catchError((errorRes) =>
            throwError(
               () => new Error(`Retry attempts failed: ${errorRes.error.message}`),
            ),
         ),
      );
   }
}

import { AsyncPipe } from "@angular/common";
import "@angular/common/locales/global/ar";
import "@angular/common/locales/global/cs";
import "@angular/common/locales/global/de";
import "@angular/common/locales/global/es";
import "@angular/common/locales/global/es-MX";
import "@angular/common/locales/global/fr";
import "@angular/common/locales/global/he";
import "@angular/common/locales/global/hu";
import "@angular/common/locales/global/it";
import "@angular/common/locales/global/ja";
import "@angular/common/locales/global/ko";
import "@angular/common/locales/global/lv";
import "@angular/common/locales/global/nl";
import "@angular/common/locales/global/no";
import "@angular/common/locales/global/pl";
import "@angular/common/locales/global/pt";
import "@angular/common/locales/global/ro";
import "@angular/common/locales/global/ru";
import "@angular/common/locales/global/sv";
import "@angular/common/locales/global/th";
import "@angular/common/locales/global/tr";
import "@angular/common/locales/global/vi";
import "@angular/common/locales/global/zh";
import { Component, inject } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { isNativeMobileApp, LogoAnimation } from "lim-ui";
import { AppRootService } from "src/app/app-root.service";
import { AppHousingComponent } from "src/app/shared/components/app-housing.component";

@Component({
   selector: "app-root",
   templateUrl: "./app-root.component.html",
   styleUrls: ["./app-root.component.scss"],
   standalone: true,
   imports: [RouterOutlet, AppHousingComponent, AsyncPipe, LogoAnimation],
})
export class AppRootComponent {
   protected playAnimation: boolean = false;
   protected appResume: boolean = false;

   public constructor() {
      const appRootService = inject(AppRootService);
      appRootService.appStartup();
      if (appRootService.animationShouldPlay()) {
         this.playAnimationForDuration(2700);
      }

      if (isNativeMobileApp()) {
         document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
               this.refreshOnAppResume();
            }
         });
      }
   }

   private refreshOnAppResume(): void {
      this.appResume = true;
      setTimeout(() => {
         this.appResume = false;
      }, 2000);
   }

   private playAnimationForDuration(milliseconds: number): void {
      this.playAnimation = true;
      setTimeout(() => {
         this.playAnimation = false;
      }, milliseconds);
   }
}

import {
   HTTP_INTERCEPTORS,
   provideHttpClient,
   withXsrfConfiguration,
} from "@angular/common/http";
import { importProvidersFrom } from "@angular/core";
import { provideAnimations } from "@angular/platform-browser/animations";
import {
   provideRouter,
   RouteReuseStrategy,
   withInMemoryScrolling,
   withPreloading,
   withRouterConfig,
} from "@angular/router";
import { LimbleTreeModule } from "@limble/limble-tree";
import { provideQueryDevTools } from "@ngneat/query-devtools";
import { provideEffects } from "@ngrx/effects";
import { provideStore } from "@ngrx/store";
import { provideStoreDevtools } from "@ngrx/store-devtools";
import { provideLimUi } from "lim-ui";
import { appInitProvider } from "src/app/app.init";
import { provideTranslations } from "src/app/languages/services/translations.provider";
import { provideInternetDisconnectedInterceptor } from "src/app/lite/connection/internet-disconnected-interceptor/internet-disconnected.interceptor";
import { PreloadFlaggedRoutesService } from "src/app/lite/preload-flagged-routes.service";
import { routes } from "src/app/routes";
import { RetryInterceptor } from "src/app/shared/interceptors/retry-interceptor";
import { CustomRouteReuseStrategy } from "src/app/shared/services/routing/custom-route-reuse-strategy";
import { provideQueryClientPersistence } from "src/app/tasks/services/provide-query-client-persistance";
import { environment } from "src/environments/environment";

export const appConfig = {
   providers: [
      importProvidersFrom(LimbleTreeModule),

      provideHttpClient(
         withXsrfConfiguration({
            cookieName: "XSRF-TOKEN",
            headerName: "X-XSRF-TOKEN",
         }),
      ),
      appInitProvider,
      provideLimUi(),
      provideAnimations(),
      provideHttpClient(),
      provideRouter(
         routes,
         withInMemoryScrolling({ scrollPositionRestoration: "top" }),
         withRouterConfig({
            paramsInheritanceStrategy: "always",
         }),
         // We want the browser to cache certain routes right away.
         // For example, Lite routes must be available offline.
         withPreloading(PreloadFlaggedRoutesService),
      ),
      { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
      provideStore(),
      provideEffects(),
      provideStoreDevtools({ connectInZone: true }),
      provideInternetDisconnectedInterceptor(),
      { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
      // TanStack Query
      environment.production ? [] : provideQueryDevTools(),
      provideQueryClientPersistence(),
      provideTranslations({
         // TODO: change this so we dont have to explicitly add them
         languages: ["en"],
      }),
   ],
};

import type { EnvironmentProviders } from "@angular/core";
import { isDevMode } from "@angular/core";
import { provideTransloco, provideTranslocoScope } from "@jsverse/transloco";
import { TranslationsHttpLoader } from "src/app/languages/services/translations-http-loader";

type TranslationOptions = {
   languages: string[];
};

/**
 * This is the Root Provider to set up the translations for the application.
 * Setups the provider for translations and the custom loader for the translations.
 *
 *  @param options
 */
export function provideTranslations(options: TranslationOptions): EnvironmentProviders[] {
   return provideTransloco({
      config: {
         availableLangs: [...options.languages],
         defaultLang: "en",
         reRenderOnLangChange: true,
         prodMode: !isDevMode(),
      },
      loader: TranslationsHttpLoader,
   });
}

/**
 * This is the provider to set up the scope.
 * It will be used either in components or in the routes
 * @param scope
 */
export function provideTranslationScope(scope: string) {
   return provideTranslocoScope(scope);
}

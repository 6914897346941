import { APP_INITIALIZER } from "@angular/core";
import { InternetDisconnectedAxiosInterceptorService } from "src/app/lite/connection/internet-disconnected-interceptor/internet-disconnected-axios-interceptor.service";
import { HeapService } from "src/app/shared/external-scripts/heap.service";
import { initializeRetryAxiosInterceptor } from "src/app/shared/interceptors/retry-axios-interceptor";

export const appInitProvider = {
   provide: APP_INITIALIZER,
   useFactory: init,
   multi: true,
   deps: [HeapService, InternetDisconnectedAxiosInterceptorService],
};

/** Intended to run before Angular finishes bootstrapping */
function init(
   heapService: HeapService,
   disconnectedAxiosInterceptorService: InternetDisconnectedAxiosInterceptorService,
): () => Promise<void> {
   return async () => {
      disconnectedAxiosInterceptorService.setInterceptor();
      initializeRetryAxiosInterceptor();
      await heapService.load();
   };
}

import { BaseRouteReuseStrategy, type ActivatedRouteSnapshot } from "@angular/router";

/**
 * @deprecated
 * This class is used for backwards compatibility with the old routing system.
 * It is recommended that new components rely on the params observables provided by
 * the ActivatedRoute provider instead of relying on route reloads triggered by this
 * class.
 */
export class CustomRouteReuseStrategy extends BaseRouteReuseStrategy {
   /**
    * Tells the Angular Router not to reuse a route if the path params are different
    *
    * @remarks
    * By default, the Angular router will ignore changes in the url path if the
    * matched route remains the same. It is expected that the routed component
    * will handle changes in the url path and query params itself using the
    * observables provided by the ActivatedRoute provider.
    *
    * Unfortunately many of our components are not currently set up to use those
    * observables, and instead rely on component destruction and reconstruction
    * when the path params change. This is because many of our components have
    * historically relied on a different routing system.
    *
    * This bit of code tells Angular to reload a route (and therefore reinitialize
    * the components of that route) if the url path changes. Note that query param
    * and fragment changes will not cause a reload, only changes to path params.
    */
   public override shouldReuseRoute(
      futureRouteSnapshot: ActivatedRouteSnapshot,
      currentRouteSnapshot: ActivatedRouteSnapshot,
   ) {
      return (
         super.shouldReuseRoute(futureRouteSnapshot, currentRouteSnapshot) &&
         JSON.stringify(futureRouteSnapshot.params) ===
            JSON.stringify(currentRouteSnapshot.params)
      );
   }
}

import { Injectable } from "@angular/core";
import type { PreloadingStrategy, Route } from "@angular/router";
import { of, type Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class PreloadFlaggedRoutesService implements PreloadingStrategy {
   public preload(route: Route, load: () => Observable<any>): Observable<unknown> {
      if (route.data?.preload) {
         return load();
      }
      return of(null);
   }
}

import type { Routes } from "@angular/router";

export const routes: Routes = [
   {
      path: "onboardingManagement",
      loadChildren: async () =>
         import(
            "./onboarding/components/onboardingManagementPage/onboardingManagement.routes"
         ),
      data: { routeName: "onboardingManagement" },
   },
   {
      path: "lite",
      loadChildren: async () => import("./lite/lite.routes"),
      data: { routeName: "lite", preload: true },
   },
   {
      path: "",
      loadChildren: async () => import("./shared/components/app-housing.routes"),
      data: { routeName: "app-housing" },
   },
];

export default routes;

import type { HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { HTTP_INTERCEPTORS, HttpErrorResponse } from "@angular/common/http";
import type { Provider } from "@angular/core";
import { Injectable, inject } from "@angular/core";
import { catchError } from "rxjs";
import { ConnectionService } from "src/app/lite/connection/connection.service";
import { InternetDisconnectedError } from "src/app/lite/connection/internet-disconnected-interceptor/internet-disconnected.error";

@Injectable()
class InternetDisconnectedInterceptor implements HttpInterceptor {
   private readonly connection = inject(ConnectionService);

   public intercept(req: HttpRequest<any>, next: HttpHandler) {
      return next
         .handle(req)
         .pipe(catchError(async (error) => this.checkForDisconnection(error)));
   }

   private async checkForDisconnection(error: unknown): Promise<never> {
      const disconnected = await this.communicationWithLimbleServersIsLost(error);
      if (!disconnected) throw error;
      this.connection.setNoInternet();
      throw new InternetDisconnectedError();
   }

   /**
    * @returns true if "something [went] wrong on the client side such as a network
    * error that prevented the request from completing successfully or an exception
    * thrown in an RxJS operator". Returns false otherwise, meaning the error was sent
    * back from the server and successfully received by the client.
    */
   private isClientSideError(error: HttpErrorResponse): boolean {
      // https://angular.io/guide/http-handle-request-errors#getting-error-details
      return error.status === 0;
   }

   private async communicationWithLimbleServersIsLost(error: unknown): Promise<boolean> {
      return (
         this.connection.isOnline() &&
         error instanceof HttpErrorResponse &&
         this.isClientSideError(error) &&
         (await this.connection.isPingSuccess()) === false
      );
   }
}

export function provideInternetDisconnectedInterceptor(): Provider {
   return {
      provide: HTTP_INTERCEPTORS,
      useClass: InternetDisconnectedInterceptor,
      multi: true,
   };
}
